<div fxLayout="row" style="padding-left: 2px; padding-right: 2px;">
  <div fxFlex.gt-md="calc(50% -1280px)" fxFlex="0"></div>

  <div
    fxFlex.gt-md="1280px"
    fxLayout="row wrap"
    fxLayoutWrap
    class="card-deck-container"
  >
    <div
      class="card-container card-width"
      fxFlex.gt-md="calc(25% - 8px)"
      fxFlex.lt-lg="calc(33% - 8px)"
      fxFlex.lt-md="calc(50% - 8px)"
      fxFlex.lt-sm="100%"
      *ngFor="let item of items"
    >
      <mat-card fxFlex="grow">
        <picture>
          <source srcset="{{item.image}}.webp" type="image/webp" />
          <source srcset="{{item.image}}.png" type="image/jpeg" />
          <img
            class="card-image"
            mat-card-image
            src="{{ item.image }}.png"
            alt="Image indicating project type"
          />
        </picture>
        <mat-card-header>
          <mat-card-title>
            <h3>{{ item.title }}</h3>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="card-content">
          <markdown ngPreserveWhitespaces>
            {{ item.blurb }}
          </markdown>
        </mat-card-content>
        <mat-card-actions align="end" class="card-content">
          <a
            color="accent"
            *ngIf="item.viewButton"
            href="{{ item.viewButton.link }}"
            mat-button
          >
            {{ item.viewButton.name }}
          </a>
          <a
            color="accent"
            *ngIf="item.sourceButton"
            href="{{ item.sourceButton.link }}"
            mat-button
          >
            {{ item.sourceButton.name }}
          </a>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
  <div fxFlex.gt-md="calc(50% -1280px)" fxFlex="0"></div>
</div>

<app-code-list></app-code-list>
