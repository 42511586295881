<div fxLayout="row" style="padding-left: 2px; padding-right: 2px;">
  <div fxFlex.gt-sm="calc(50% - 900px)" fxFlex="0"></div>

  <mat-card fxFlex="grow" fxFlex.gt-sm="900px">
    <mat-card-header>
      <mat-card-title>
        <h2>About</h2>
      </mat-card-title>
      <mat-card-subtitle>
        <h3>Bryn Bennett "LaserFlash"</h3>
      </mat-card-subtitle>
    </mat-card-header>
    <picture>
      <source srcset="assets/images/skiing.webp" type="image/webp" />
      <source srcset="assets/images/skiing.png" type="image/jpeg" />
      <img
        mat-card-image
        src="assets/images/skiing.png"
        alt="Bryn skiing on Mt Ruapehu"
      />
    </picture>
    <mat-card-content class="about-content">
      <h3>Me</h3>
      <p>
        Currently I work at <a href="https://www.catalyst.net.nz/">Catalyst</a> 
        as a frontend developer, mostly working with React, D3 and Drupal themes. 
        Some of my other passions include: yachting, skiing, and
        gaming. I also develop and work on some of my own personal projects, most actively a skin for the <a href="https://steamchatskinning.tk/" >steam chat</a>.
      </p>
      <p>
        I also have developed a system for Yacht clubs to log
        how their boats are used and breakages that they may have. This
        solution is in the form of a web app and has been deployed and is in
        active use by:
      </p>
      <ul>
        <li>Wellington Youth Sailing Trust</li>
        <li>Tup Radford Intercollegiate Yachting Academy</li>
        <li>New Plymouth Yacht Club</li>
        <li>Queen Charlotte Yacht Club</li>
      </ul>
    </mat-card-content>
    <mat-card-actions align="end" class="card-content">
      <a color="accent" href="https://github.com/LaserFlash" mat-button
        >GitHub</a
      >
    </mat-card-actions>
  </mat-card>

  <div fxFlex.gt-sm="calc(50% - 900px)" fxFlex="0"></div>
</div>
