<mat-toolbar color="primary">
  <span>
    <picture>
      <source srcset="assets/images/logo.webp" type="image/webp" />
      <source srcset="assets/images/logo.png" type="image/jpeg" />
      <img
        class="menuLogo"
        src="assets/images/logo.png"
        alt="Bryn Bennett 'LaserFlash'"
      />
    </picture>
  </span>
  <span class="menuSpacer"></span>
  <span class="menuTitle">
    <h1 class="firstH1">{{ titleFirst }}</h1>
    <h1 class="secondH1">{{ titleSecond }}</h1>
  </span>
  <!-- This fills the remaining space of the current row -->
  <span fxFlex></span>
  <span>
    <nav mat-tab-nav-bar>
      <a
        mat-tab-link
        backgroundColor="accent"
        *ngFor="let routeLink of routeLinks; let i = index"
        [routerLink]="routeLink.link"
        routerLinkActive
        #rla="routerLinkActive"
        [active]="rla.isActive"
      >
        {{ routeLink.label }}
      </a>
    </nav>
  </span>
</mat-toolbar>

<div style="background:#37474f;">
  <div id="scrollId" class="content-spacer"></div>

  <main
    [@routerTransition]="getState(o)"
    class="content"
    (swipeleft)="swipe($event.type)"
    (swiperight)="swipe($event.type)"
  >
    <router-outlet #o="outlet"></router-outlet>
  </main>
</div>
